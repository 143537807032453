<template>
  <div>
    <h1 class="ui-title-page">RealHub Assisted Plans</h1>
    <div class="triagl triagl-btm"></div>
    <template v-if="!loading">
      <div class="section-area section_mod-5" id="overview">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 has-edit">
              <div class="description">
                <edit-button @click="edit_intro = true" />
                <div v-html="intro"></div>
              </div>
            </div>
            <div class="col-xs-12 has-edit" v-if="!edit_overview">
              <edit-button @click="edit_overview = true" />
              <ul class="advantages-list advantages-list_left">
                <li
                  class="advantages-list__item"
                  v-for="(item, i) in overview.one"
                  :key="`one-${i}`"
                >
                  <div class="pennant"><i class="icon fa fa-trophy"></i></div>
                  <div class="advantages-list__inner">
                    <h3 class="advantages-list__title">
                      {{ item.title }}
                    </h3>
                    <p class="ui-text">
                      {{ item.content }}
                    </p>
                  </div>
                </li>
              </ul>
              <div class="figure">
                <img
                  :src="overview.image"
                  alt="Foto"
                  width="400"
                  height="340"
                  class="img-responsive"
                />
              </div>
              <ul class="advantages-list advantages-list_right">
                <li
                  class="advantages-list__item"
                  v-for="(item, i) in overview.two"
                  :key="`two-${i}`"
                >
                  <div class="pennant"><i class="icon fa fa-trophy"></i></div>
                  <div class="advantages-list__inner">
                    <h3 class="advantages-list__title">
                      {{ item.title }}
                    </h3>
                    <p class="ui-text">
                      {{ item.content }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-xs-12 has-edit" v-if="edit_overview">
              <form @submit.prevent="updateOverview">
                <h1 class="text-center mb-2">Edit Content</h1>
                <ul class="advantages-list advantages-list_left">
                  <li
                    class="advantages-list__item"
                    v-for="(item, i) in overview.one"
                    :key="`one-${i}`"
                  >
                    <div class="pennant"><i class="icon fa fa-trophy"></i></div>
                    <div class="advantages-list__inner">
                      <h3 class="advantages-list__title">
                        <input
                          type="text"
                          v-model="item.title"
                          class="form-control"
                        />
                      </h3>
                      <p class="ui-text">
                        <textarea
                          class="form-control"
                          v-model="item.content"
                        ></textarea>
                      </p>
                    </div>
                  </li>
                </ul>
                <div class="figure">
                  <label
                    >Change Image
                    <small class="text-muted">Optional</small></label
                  >
                  <input
                    type="file"
                    placeholder="Change Image"
                    ref="overview_image"
                    class="form-control"
                  />
                  <br />
                  <img
                    :src="overview.image"
                    alt="Foto"
                    width="400"
                    height="340"
                    class="img-responsive"
                  />
                </div>
                <ul class="advantages-list advantages-list_right">
                  <li
                    class="advantages-list__item"
                    v-for="(item, i) in overview.two"
                    :key="`two-${i}`"
                  >
                    <div class="pennant"><i class="icon fa fa-trophy"></i></div>
                    <div class="advantages-list__inner">
                      <h3 class="advantages-list__title">
                        <input
                          type="text"
                          v-model="item.title"
                          class="form-control"
                        />
                      </h3>
                      <p class="ui-text">
                        <textarea
                          class="form-control"
                          v-model="item.content"
                        ></textarea>
                      </p>
                    </div>
                  </li>
                </ul>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <a
                      href="#"
                      @click.prevent="edit_overview = false"
                      class="btn btn-warning"
                      ><i class="lni lni-close"></i> Cancel</a
                    >
                  </div>
                  <div class="col-md-6 text-right">
                    <button class="btn btn-success">
                      <i class="lni lni-save"></i> Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <section
        class="section-area section_price bg-gray has-edit"
        id="pricing"
        v-if="!edit_pricing"
      >
        <edit-button @click="edit_pricing = true" />
        <h2 class="ui-title-block">easy on your pocket</h2>
        <div class="ui-subtitle-block">our pricing details</div>
        <div class="border-color border-color_small"></div>
        <ul class="list-price">
          <li
            class="list-price__item"
            v-for="(item, i) in pricing"
            :key="`price-${i}`"
          >
            <h3 class="list-price__title">{{ item.title }}</h3>
            <span class="list-price__total"
              >KES<strong>{{ item.amount }}</strong></span
            >
            <ul class="description">
              <li
                v-for="(content, j) in item.content"
                :key="`price-content-${j}`"
              >
                {{ content }}
              </li>
            </ul>
            <div class="border-color"></div>
            <router-link
              :to="{ name: 'field-assistants.book', params: { id: item.id } }"
              class="btn btn-default"
              >BUY NOW</router-link
            >
          </li>
        </ul>
      </section>
      <section
        class="section-area section_price bg-gray has-edit"
        id="pricing"
        v-if="edit_pricing"
      >
        <a href="#" @click.prevent="edit_pricing = false" class="edit-button"
          >Cancel</a
        >
        <div class="ui-subtitle-block">Edit Pricing</div>
        <div class="border-color border-color_small"></div>
        <form @submit.prevent="updatePricing">
          <ul class="list-price">
            <li
              class="list-price__item"
              v-for="(item, i) in pricing"
              :key="`price-${i}`"
            >
              <h3 class="list-price__title">
                <input type="text" v-model="item.title" class="form-control" />
              </h3>
              <span class="list-price__total"
                >KES<strong>
                  <input
                    type="text"
                    v-model="item.amount"
                    class="form-control"
                  /> </strong
              ></span>
              <ul class="description">
                <li v-for="j in 10" :key="`price-content-${j}`">
                  <input
                    type="text"
                    v-model="item.content[j - 1]"
                    class="form-control"
                  />
                </li>
              </ul>
            </li>
          </ul>
          <div class="container">
            <div class="row mb-2">
              <div class="col-md-6 text-left">
                <a
                  href="#"
                  @click.prevent="edit_pricing = false"
                  class="btn btn-warning"
                  ><i class="lni lni-close"></i> Cancel</a
                >
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success">
                  <i class="lni lni-save"></i> Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
      <section
        class="section-area section_list-scheme triagl triagl-btm-gray bg-white"
        id="how-it-works"
      >
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <h2 class="ui-title-block">WANT SOME HELP?</h2>
              <div class="ui-subtitle-block">
                <strong>HOW WE MAKE IT HAPPEN</strong>
              </div>
              <div class="border-color border-color_center"></div>
            </div>
          </div>
          <div class="row">
            <ul class="list-scheme">
              <li class="list-scheme__item">
                <a href="javascript:void(0);" class="list-scheme__link"
                  ><i class="icon flaticon-clipboard52 helper"></i
                  ><span class="list-scheme__number">1</span>
                  <div class="list-scheme__title">
                    BOOK<span class="br">AN ORDER WITH US</span>
                  </div>
                </a>
                <span class="arrow"
                  ><i class="arrow__inner fa fa-angle-right"></i>
                </span>
              </li>
              <li class="list-scheme__item">
                <a href="javascript:void(0);" class="list-scheme__link"
                  ><i class="icon flaticon-delivery30 helper"></i
                  ><span class="list-scheme__number">2</span>
                  <div class="list-scheme__title">
                    GET A <span class="br"> FIELD ASSISTANT</span>
                  </div>
                </a>
                <span class="arrow"
                  ><i class="arrow__inner fa fa-angle-right"></i>
                </span>
              </li>
              <li class="list-scheme__item">
                <a href="javascript:void(0);" class="list-scheme__link"
                  ><i class="icon flaticon-logistics3 helper"></i
                  ><span class="list-scheme__number">3</span>
                  <div class="list-scheme__title">
                    ARRANGE <span class="br">A SITE VISIT</span>
                  </div>
                </a>
                <span class="arrow"
                  ><i class="arrow__inner fa fa-angle-right"></i>
                </span>
              </li>
              <li class="list-scheme__item">
                <a href="javascript:void(0);" class="list-scheme__link"
                  ><i class="icon flaticon-logisticsdelivery helper"></i
                  ><span class="list-scheme__number">4</span>
                  <div class="list-scheme__title">
                    FIND YOUR <span class="br">PERFECT HOME</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section
        class="section_mod-2 bg-gray"
        style="padding-top: 40px;"
        id="testimonials"
      >
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <a
                href="#"
                class="btn btn-primary float-right btn-sm"
                data-toggle="modal"
                data-target="#add-review"
                v-if="$auth.hasToken()"
                >Leave A Review</a
              >
              <div
                class="modal fade"
                id="add-review"
                tabindex="-1"
                aria-labelledby="add-review-label"
                aria-hidden="true"
                v-if="$auth.hasToken()"
              >
                <div class="modal-dialog">
                  <div class="modal-content" style="padding: 20px">
                    <div class="modal-header" style="margin-bottom: 10px">
                      <button
                        type="button"
                        class="btn btn-link float-right"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        X
                      </button>
                      <h2 class="modal-title" id="add-review-label">
                        Leave A Review
                      </h2>
                    </div>
                    <div class="py-3 px-4">
                      <form @submit.prevent="submitReview">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Your Name</label
                              ><input
                                type="text"
                                placeholder="Full Name"
                                required="required"
                                class="form-control"
                                v-model="review.name"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Your Email</label
                              ><input
                                type="text"
                                placeholder="Full Email"
                                required="required"
                                class="form-control"
                                v-model="review.email"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Your Phone Number</label
                              ><input
                                type="text"
                                placeholder="Full Phone Number"
                                required="required"
                                class="form-control"
                                v-model="review.phone_number"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Rate Your Experience</label>
                              <input-rating
                                class="py-3"
                                v-model="review.rating"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Your Comments</label
                              ><textarea
                                rows="6"
                                placeholder="Your Comments"
                                required="required"
                                class="form-control"
                                v-model="review.content"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <button class="btn btn-primary">
                                <i class="lni lni-save"></i> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        id="close-add-review-modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="ui-title-block">WHAT CLIENTS SAY</h2>
              <div class="ui-subtitle-block">THE TESTIMONIALS</div>
              <div class="border-color border-color_default"></div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <div class="row">
            <div class="col-md-6" v-for="(r, i) in reviews" :key="`slide-${i}`">
              <div class="about-autor clearfix bg-white">
                <div class="about-autor__foto">
                  <img
                    :src="r.avatar"
                    :alt="r.name"
                    height="106"
                    width="106"
                    class="img-responsive"
                  />
                </div>
                <div class="about-autor__inner">
                  <span class="about-autor__name">{{ r.name }}</span>
                  <div class="border-color"></div>
                  <div class="about-autor__text ui-text">{{ r.content }}</div>
                </div>
              </div>
            </div>
            <div v-if="reviews.length > 0" class="col-md-12">
              <pagination
                v-model="reviews_pagination.page"
                :records="reviews_pagination.records"
                @paginate="fetchReviews"
                :perPage="5"
              />
            </div>
            <div class="col-md-12 mt-2" v-if="reviews.length == 0">
              <alert type="info">There are no reviews yet.</alert>
            </div>
          </div>
        </div>
      </section>
    </template>

    <loading v-if="loading" />

    <modal :show="edit_intro" @close="edit_intro = false">
      <template slot="header">Edit Intro</template>
      <form @submit.prevent="updateIntro">
        <div class="form-group mt-2">
          <label>Content</label>
          <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
            v-model="intro"
            :init="{
              height: 350,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | image',
            }"
          />
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary">Update</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();

    this.fetchReviews()

    this.review = {
      name: this.user.name,
      email: this.user.email,
      phone_number: this.user.phone_number,
    };
  },

  data() {
    return {
      edit_intro: false,
      loading: true,
      intro: "",
      overview: { one: [], two: [], image: "" },
      edit_overview: false,
      edit_pricing: false,
      pricing: [],
      review: {},
      reviews: [],
      reviews_loading: true,
      reviews_pagination: { page: 1, records: 1 },
    };
  },

  methods: {
    fetchReviews() {
      this.reviews_loading = true;

      this.$axios
        .get(`/api/v1/reviews/movers?page=${this.reviews_pagination.page}`)
        .then((response) => {
          this.reviews_loading = false;
          this.reviews = response.data.reviews;
        });
    },

    submitReview() {
      let data = this.review;
      data.type = "movers";

      this.$axios.post(`/api/v1/reviews/movers`, data).then(() => {
        this.fetchReviews();
        this.$swal("success", "Your review has been submitted.", "Success");
        document.getElementById("close-add-review-modal").click();
      });
    },

    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/content/field-assistants").then((response) => {
        this.intro = response.data.content.intro;
        this.$emit("loaded", this.intro);
        this.overview = response.data.content.overview;
        this.pricing = response.data.content.pricing;
        this.loading = false;
      });
    },

    updateIntro() {
      let data = {
        type: "field-assistants-intro",
        content: this.intro,
      };

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_intro = false;
        this.fetch();
      });
    },

    updatePricing() {
      let data = {
        type: "field-assistants-pricing",
        content: this.pricing,
      };

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_pricing = false;
        this.fetch();
      });
    },

    updateOverview() {
      let data = new FormData();
      data.append("type", "field-assistants-overview");
      data.append("content", JSON.stringify(this.overview));

      let image = this.$refs.overview_image.files[0];

      if (image) {
        data.append("image", image);
      }

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_overview = false;
        this.fetch();
      });
    },
  },

  computed: {
    contact() {
      return this.$store.getters.getContact;
    },

    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
